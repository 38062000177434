import React from "react";
import styled from "styled-components";

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

const PageContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 12em;
`;

export function Kontakt() {
  return (
    <PageContainer>
      <PageContent>
        <div>
          <img src="/static/img/crest_fra.svg" alt="Försvarets Radioanstalt" />
          <img src="/static/img/crest_fm.svg" alt="Försvarsmakten" />
          <img src="/static/img/crest_sapo.svg" alt="Säkerhetspolisen" />
        </div>
      </PageContent>
    </PageContainer>
  );
}
