import React from "react";
import styled from "styled-components";
import { Link, Route, Routes } from "react-router-dom";

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

const PageContent = styled.div`
  width: 90%;
  max-width: 800px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 6em auto;
  margin-top: 1em;
`;

const Header = styled.div`
  width: 90%;
  max-width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  date {
    font-weight: bold;
  }

  h1 {
    margin-top: 15px;
    margin-bottom: 25px;
  }

  h2 {
    margin-top: 25px;
    margin-bottom: 10px;
  }

  p {
    margin: 4px auto;
  }

  a {
    color: white;
  }

  .border {
    &::after,
    &::before,
    span::after,
    span::before {
      content: "";
      position: absolute;
      background-color: white;
      transition: 0.5s ease;
    }

    &::after,
    &::before {
      height: 100%;
      width: 2px;
      top: -2px;
    }

    &::after {
      left: -2px;
    }

    &::before {
      right: -2px;
    }

    span::after,
    span::before {
      height: 2px;
      width: 100%;
      left: -2px;
    }
    span::after {
      top: -2px;
    }

    span::before {
      bottom: -2px;
    }

    &:hover:after {
      transform: translatex(-12px);
    }

    &:hover:before {
      transform: translatex(12px);
    }

    &:hover span::after {
      transform: translatey(-12px);
    }

    &:hover span::before {
      transform: translatey(12px);
    }
  }

  .swap {
    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 1rem 2rem;
      box-sizing: border-box;
      opacity: 0;
      color: black;
      transition: 0.35s ease;
    }

    &::before {
      content: "";
      left: 0;
      top: 0;
      position: absolute;
      width: 0;
      height: 100%;
      background-color: white;
      transition: 0.5s ease;
    }

    &:hover {
      &:before {
        width: 100%;
      }

      span {
        opacity: 1;
      }
    }
  }

  .future {
    &::after,
    &::before {
      content: "";
      position: absolute;
      border: 2px solid white;
      width: calc(100% + 15px);
      height: 60px;
      transition: 0.5s ease;
    }

    &::after {
      top: -15px;
      left: -15px;
      border-right: none;
      border-bottom: none;
    }

    &::before {
      bottom: -15px;
      right: -15px;
      border-left: none;
      border-top: none;
    }

    &:hover:after,
    &:hover:before {
      width: calc(100% - 30px);
      height: 80px;
    }
  }
`;

export function Info() {
  return (
    <PageContainer>
      <PageContent>
        <Header>
          <h1>Information</h1>

          <h2>Om Undutmaning</h2>
          <p className="text-left">
            Undutmaning är en myndighetsgemensam CTF-tävling (Capture the Flag)
            som årligen arrangeras av FRA, Must och Säpo sedan 2022. Tävlingen
            anordnas för att visa på myndigheterna som arbetsgivare, och för att
            attrahera personer som har den kompetens som eftersöks bland annat
            inom IT-säkerhet.
            <br />
            <br />
            Då omvärlden förändras i en allt högre takt och att det råder stor
            konkurrens om den bästa kompetensen på arbetsmarknaden inom områden
            som exempelvis IT-säkerhet är förmågan att attrahera, rekrytera,
            utveckla och behålla rätt kompetens avgörande för att lyckas med
            myndigheternas uppdrag. Att anordna en gemensam tävling är ett sätt
            att både lyfta myndighetens egna medarbetares kompetens, men även
            att ge potentiella medarbetare en inblick i respektive myndighets
            verksamhet. En gemensam utmaning för både FRA, Must och Säpo vad
            gäller rekrytering är att stora delar av verksamheterna är
            sekretessbelagda, vilket gör att vi inte kan berätta om allt vi gör
            - genom Undutmaning vill vi därför istället visa hur det kan vara
            att jobba hos oss, med problem och frågeställningar som några av
            våra medarbetare dagligen möts av.
          </p>

          <h2>Vad är en CTF?</h2>
          <p className="text-left">
            CTF (Capture the Flag) är en tävling där deltagare ska lösa olika
            typer av IT-säkerhetsrelaterade problem, enskilt eller i lag.
            Tävlingen består av utmaningar inom olika kategorier som t.ex.
            programmering, IT-forensik och krypto där det göms "flaggor" som
            deltagarna ska komma åt, inbäddade i kryptografiska nycklar,
            lösenord, hemliga meddelanden eller andra former av digitala
            artefakter. För varje flagga som hittas får deltagaren/laget poäng.
            Vinnaren är den som har flest poäng vid tävlingens slut.
            <br />
            <br />
            Poäng kan delas ut till de första som hittar en flagga eller
            beräknas utgående från hur många som lyckas lösa varje utmaning.
            Undutmaning är av den senare sorten.
          </p>

          <h3>Exempel</h3>
          <p className="text-left">
            Ett exempel på en typisk CTF utmaning inom forensik kan vara att
            spelaren får en fil som innehåller exempelvis inspelad
            nätverkstrafik. Det spelaren behöver göra för att få ut flaggan kan
            vara att välja ut rätt delar av nätverkstrafiken och hämta ut
            information så som filer eller andra typer av program, som i sin tur
            behöver mer bearbetning för att ge spelaren flaggan.
            <br />
            <br />
            Ett annat exempel på en typisk CTF inom kategorin pwn, är att
            spelaren får ladda ner en binär fil som vanligtvis körs på en
            fjärrserver. Målet är att hitta brister i binären genom dynamisk
            eller statisk analys och nyttja den för att få godtycklig
            kod-exekvering på en server som i sin tur returnerar flaggan.
          </p>

          <h2>Syfte</h2>
          <p className="text-left">
            Syftet med en CTF-tävling är att främja lärande och tillämpning av
            praktiska färdigheter inom IT-säkerhet genom att låta deltagarna
            praktiskt tillämpa sina kunskaper på verkliga problem och
            utmaningar. En CTF kan vara individuell eller lagbaserad och i
            Undutmaning kan du delta både som del i ett lag eller på egen hand.
            <br />
            <br />
            CTF-tävlingar är inte bara en möjlighet att tävla, utan också en
            plattform för kunskapsdelning, samarbete och gemenskap inom
            IT-säkerhetsvärlden. Utmaningarna i Undutmaning är utvecklade av
            medarbetare på myndigheterna och syftar både till att belysa viktig
            och intressant teknik samt att på ett lekfullt sätt visa på olika
            typer av problem vi kan stöta på i vår vardag.
          </p>
        </Header>
      </PageContent>
    </PageContainer>
  );
}
