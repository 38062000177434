import React from "react";
import styled from "styled-components";

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

const PageContent = styled.div`
  width: 90%;
  max-width: 800px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 6em auto;
  margin-top: 1em;

  .pure-button-primary {
    background: var(--color-lime);
    text-shadow: 0px 0px 5px rgb(0 0 0 / 89%);
  }
`;


const Header = styled.div`
  width: 90%;
  max-width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  date {
    font-weight: bold;
  }

  h1 {
    margin-top: 15px;
    margin-bottom: 25px;
  }

  h2 {
    margin-top: 25px;
    margin-bottom: 10px;
  }

  p {
    margin: 4px auto;
  }

  a {
    color: white;
  }
`;

export function Registrera() {
    const [isRegistered, setIsRegistered] = React.useState(false);

    async function handleSubmit(event) {
        event.preventDefault();
        if (event.target.accept.checked === false) {
            alert("Du måste godkänna policy och regler för att kunna registrera dig.");
            return;
        }
        // eslint-disable-next-line no-undef
        if (grecaptcha === undefined) {
            alert("Recaptcha saknas. Vänligen försök igen om en stund.");
            return;
        }
        // eslint-disable-next-line no-undef
        grecaptcha.ready(function () {
            // eslint-disable-next-line no-undef
            grecaptcha.execute('6LcNvIMpAAAAAIPbr1NuhjS8ViIAaoIx9gBVdNqa', { action: 'submit' }).then(async function (token) {
                // Send token to your server for verification together with the user request.
                console.log("Form submitted");
                const newRegistration = {
                    name_first: event.target.firstname.value,
                    name_last: event.target.lastname.value,
                    email: event.target.email_event.value,
                    email_ctf: event.target.email_ctf.value,
                    team: event.target.team.value,
                    tour: event.target.tour.checked,
                    recaptcha: token,
                };
                console.log(newRegistration);
                const response = await fetch("/api/attendees", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(newRegistration),
                });
                if (response.ok) {
                    const body = await response.json();
                    if (body.status === "accepted") {
                        console.log("Registration successful", body);
                        setIsRegistered(true);
                        return;
                    }
                }
                console.log("Registration failed", response);
            });
        });
    }

    const registrationHasEnded = new Date() > new Date("Mar 24 2024 23:59:59 UTC+0100");

    return (
        <>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/purecss@3.0.0/build/pure-min.css" integrity="sha384-X38yfunGUhNzHpBaEBsWLO+A0HDYOQi8ufWDkZ0k9e0eXz/tH3II7uKZ9msv++Ls" crossorigin="anonymous"></link>
            {/*
                <script src="https://www.google.com/recaptcha/enterprise.js?render=6LdoNGIpAAAAAM4xC9NV7GpEG89BpeiNfinQwltT" />
                https://cloud.google.com/recaptcha-enterprise/docs/samples?_ga=2.187659245.-419800409.1706735251
                https://console.cloud.google.com/security/recaptcha/6LdoNGIpAAAAAM4xC9NV7GpEG89BpeiNfinQwltT/integration?authuser=0&project=undutmaning-1706687549626
            */}
            <PageContainer>
                <PageContent>
                    <Header>
                        <p>
                            <date>25 april 2024</date>
                        </p>
                        <h1>Registrering för eventet</h1>
                        <p className="text-justify">
                            Registrera dig för att få chans att delta på det fysiska eventet på ett museum i Stockholm den 25 april 2024.
                            Detta är separat från tävlingen men <strong>kräver</strong> att du har deltagit i tävlingen för att kunna delta på eventet.
                            Observera att registrering inte garanterar plats på eventet. Vi kommer att kontakta dig om du har fått en plats.
                            <br></br>
                            Läs mer om eventet <a href="/event">här</a>.
                            <br></br>
                            <br></br>
                        </p>
                        {registrationHasEnded ? (
                            <div>
                                <h2>Tack för alla registreringar!</h2>
                                <p>
                                    {/* Du är nu registrerad till eventet. {/* Om du har några frågor, vänligen
                                    kontakta oss på <a href="mailto:info@undutmaning.se">info@undutmaning.se</a>. */}
                                    Registreringen till det fysiska eventet har stängt. <br />
                                    Om du har några frågor, vänligen kontakta någon med rollen "Jarl" på Discord. <br />
                                    Gå med i vår gemensamma Undutmaning Discord server <a href="/community">här</a>.
                                </p>
                            </div>
                        ) : (isRegistered ? (
                            <>
                                <div>
                                    <h2>Tack för din registrering!</h2>
                                    <p>
                                        Du är nu registrerad till eventet. Om du har några frågor, vänligen
                                        kontakta administatörerna med rollen "Jarl" på Discord.
                                    </p>
                                </div>
                            </>
                        ) : (
                            <>
                                <form id="register-form" class="pure-form pure-form-aligned" action="" onSubmit={handleSubmit}>
                                    <fieldset class="mono">
                                        <div class="pure-control-group">
                                            <label for="firstname">Förnamn</label>
                                            <input type="text" id="firstname" placeholder="First Name" required />
                                            <span class="pure-form-message-inline">Obligatoriskt</span>
                                        </div>
                                        <div class="pure-control-group">
                                            <label for="lastname">Efternamn</label>
                                            <input type="text" id="lastname" placeholder="Last Name" required />
                                            <span class="pure-form-message-inline">Obligatoriskt</span>
                                        </div>
                                        <div class="pure-control-group">
                                            <label for="email_event">E-post Event</label>
                                            <input type="email" id="email_event" placeholder="Email Address" required />
                                            <span class="pure-form-message-inline">Obligatoriskt</span>
                                        </div>
                                        <div class="pure-control-group">
                                            <label for="email_ctf">E-post CTF</label>
                                            <input type="email" id="email_ctf" placeholder="Email Address" required />
                                            <span class="pure-form-message-inline">Obligatoriskt</span>
                                        </div>
                                        <div class="pure-control-group">
                                            <label for="team">Lag CTF</label>
                                            <input type="text" id="team" placeholder="Team Name" />
                                            <span class="pure-form-message-inline">Valfritt&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        </div>
                                        <span class="pure-form-message-inline text-justify">
                                            <br />
                                            <b>OBS!</b>{" "}
                                            Om du planerar att använda en annan e-postadress för CTF tävlingen än
                                            den du använder för att registrera dig till det fysiska eventet, skriv då in den e-post i fältet <code>E-post CTF</code>.
                                            Om du vill bli kontaktad på en annan e-postadress än den du plannerar att använda för att registrera dig på CTF tävlingen,
                                            skriv in den e-postadressen i fältet <code>E-post Event</code>.
                                        </span>
                                        <div class="pure-controls">
                                            <label for="tour" class="pure-checkbox">
                                                <input type="checkbox" id="tour" /> Jag vill ha en guidad tur på museet.
                                            </label>
                                            <label for="accept" class="pure-checkbox">
                                                <input type="checkbox" id="accept" required /> Jag godkänner <a href="/rules">policy och regler</a>.
                                            </label>
                                            <button type="submit" class="pure-button pure-button-primary">Submit</button>
                                        </div>
                                    </fieldset>
                                </form>
                                <h2 className="mono"><b>OBS:</b> Registreringen stänger den 24 april kl 23:59</h2>
                            </>
                        ))}
                    </Header>
                </PageContent>
            </PageContainer>
        </>
    );
}
