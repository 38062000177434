import React from "react";
import styled from "styled-components";
import { Link, Route, Routes } from "react-router-dom";

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

const PageContent = styled.div`
  width: 90%;
  max-width: 800px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 6em auto;
  margin-top: 1em;

  .mt-50 {
    margin-top: 50px;
  }
`;

const Header = styled.div`
  width: 90%;
  max-width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  date {
    font-weight: bold;
  }

  h1 {
    margin-top: 15px;
    margin-bottom: 25px;
  }

  h2 {
    margin-top: 25px;
    margin-bottom: 10px;
  }

  p {
    margin: 4px auto;
  }

  a {
    color: white;
  }

  .border {
    &::after,
    &::before,
    span::after,
    span::before {
      content: "";
      position: absolute;
      background-color: white;
      transition: 0.5s ease;
    }

    &::after,
    &::before {
      height: 100%;
      width: 2px;
      top: -2px;
    }

    &::after {
      left: -2px;
    }

    &::before {
      right: -2px;
    }

    span::after,
    span::before {
      height: 2px;
      width: 100%;
      left: -2px;
    }
    span::after {
      top: -2px;
    }

    span::before {
      bottom: -2px;
    }

    &:hover:after {
      transform: translatex(-12px);
    }

    &:hover:before {
      transform: translatex(12px);
    }

    &:hover span::after {
      transform: translatey(-12px);
    }

    &:hover span::before {
      transform: translatey(12px);
    }
  }

  .swap {
    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 1rem 2rem;
      box-sizing: border-box;
      opacity: 0;
      color: black;
      transition: 0.35s ease;
    }

    &::before {
      content: "";
      left: 0;
      top: 0;
      position: absolute;
      width: 0;
      height: 100%;
      background-color: white;
      transition: 0.5s ease;
    }

    &:hover {
      &:before {
        width: 100%;
      }

      span {
        opacity: 1;
      }
    }
  }

  .future {
    &::after,
    &::before {
      content: "";
      position: absolute;
      border: 2px solid white;
      width: calc(100% + 15px);
      height: 60px;
      transition: 0.5s ease;
    }

    &::after {
      top: -15px;
      left: -15px;
      border-right: none;
      border-bottom: none;
    }

    &::before {
      bottom: -15px;
      right: -15px;
      border-left: none;
      border-top: none;
    }

    &:hover:after,
    &:hover:before {
      width: calc(100% - 30px);
      height: 80px;
    }
  }

  .split {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    margin-top: 20px;
    width: 100%;
    flex-wrap: wrap;
  }

  .split > *:not(:last-child) {
    margin-right: 20px;
  }

  @media (max-width: 500px) {
    .split > *:not(:last-child) {
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
`;

export function Community() {
    return (
        <PageContainer>
            <PageContent>
                <Header>
                    <h1>Gemenskap</h1>

                    <div className="split">
                        <p className="text-left" style={{ flex: 1 }}>
                            <h2>Gå med i vår Discord</h2>
                            Undutmaning har en egen Discord-server där du kan chatta med andra
                            deltagare och personer inom cyber och IT-säkerhet, ställa frågor
                            om tävlingen till våra medarbetare och moderatorer.
                            <br />
                            <br />
                            Klicka på <code>Join Discord</code> knappen för att gå med i vår
                            Discord-server.
                        </p>
                        <iframe
                            title="discord"
                            src="https://discord.com/widget?id=955054248050192465&theme=dark"
                            width="350"
                            height="500"
                            allowtransparency="true"
                            frameborder="0"
                            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
                            style={{ flex: 1 }}
                        />
                    </div>

                    <h2 class="mt-50">Teman genom åren</h2>
                    <p className="text-left">
                        Som en del av Undutmaning har vi valt att ha olika teman för varje
                        år. Dessa teman har även haft en detaljerad berättelse som har följt
                        med under hela tävlingen. Här nedan kan du läsa mer om de olika
                        handlingarna och teman som har varit med under tidigare år.
                    </p>
                    <h3 class="mt-50">
                        2024 - Ur led är tiden!
                    </h3>
                    <p className="text-left">

                        Det är en strålande marsmorgon, och Harriet von Hopp har smugit
                        sig upp tidigt för att baka en födelsedagstårta, enligt farmors
                        recept, till sin man Harald, som fyller år. I gryningsdunklet
                        förväxlar hon olyckligtvis grytvanten med Haralds PowerGlove<sup>TM</sup>{" "}
                        och berättelsen tar en oväntad vändning.
                        <br />
                        <br />
                        När hon ska ställa in tårtan i ugnen, snubblar hon på förklädet
                        och råkar spilla vaniljkräm över handsken, vilket resulterar i en
                        kortslutning, ett systemfel och en enorm urladdning. En reva i
                        rumtiden uppstår och innan hon hunnit reagera dras hon in i den.
                        Ut blir in, upp blir ned och fram blir bak ett ögonblick, innan
                        hon kan se klart igen och finner sig stå i en skogsdunge, omgiven
                        av en grupp underligt klädda figurer. Deras kläder är gjorda i
                        grovt ylle och läder och deras ansikten är häpna.
                        <br />
                        <br />
                        Harriet ser sig förvånat omkring.
                        <br />
                        <br />
                        &quot;Lajvar ni eller?&quot; frågar hon.
                        <br />
                        <br />

                        Figurernas ansiktsuttryck går snabbt från överraskade, till
                        förvirrade och sedan misstänksamma. De griper efter dolkar i sina
                        bälten.
                        <br />
                        <br />
                        &quot;Vem är du, och hur dök du upp från ingenstans? Är det Harald
                        som ligger bakom?&quot; ropar de.
                        <br />
                        <br />
                        Ett tag är stämningen hotfull och förvirrad, tills Harriet lyckas
                        lugna ner gruppen. Det visar sig att handsken har förflyttat henne
                        i tiden, till år 940. Den Harald som de nämnde är inte hennes
                        älskade make, utan Harald Blåtand, en grym tyrann som förtrycker
                        folket med höga skatter, hårt arbete och bristfällig munhygien.
                        Nyligen har han dessutom uppfunnit ett nytt sätt att kommunicera
                        på och han kräver nu att alla ska använda sig av det nya sättet
                        att prata med, och skicka meddelanden till, varandra. Kung Blåtand
                        uppfinner dock hellre än bra och hans nya protokoll gör livet surt
                        för invånarna som hela tiden tappar kontakten med varandra och
                        plötsligt har fått överraskande svårt att samtala med någon över
                        tio meter bort.
                        <br />
                        <br />
                        Invånarna har nått bristningsgränsen och en motståndsrörelse har
                        bildats.
                        <br />
                        <br />
                        Nu har Harriet dykt upp mitt i ett av deras möten!
                        <br />
                        <br />
                        Vilken soppa! Både forntiden och framtiden står på spel! Kan du
                        hjälpa Harriet och motståndsrörelsen att omintetgöra Haralds onda
                        planer, stoppa hans bristfälliga protokoll och på så sätt rädda
                        både den förtryckta befolkningen samt minska lidandet för otaliga
                        framtida IT-specialister? Dessutom måste hon ju hitta ett sätt att
                        ta sig hem, innan tårtan bränns vid!
                        <br />
                        <br />
                    </p>
                    <h3 class="mt-50">2023 - Rädda farmors recept!</h3>
                    <p className="text-left">
                        Harald vaknar med ett ryck och känner genast igen en bekant känsla,
                        han är bakis. Det blev på tok för mycket socker igår... Han vaknar
                        upp i sin lilla stuga med en ring på fingret, han har gift sig med
                        Harriet von Schwartzwald! Den lilla stugan befinner sig i vad många
                        skulle kalla för Sveriges bakficka, nämligen Bullerbyn.
                        <br />
                        <br />
                        Det var även en tillställning igår, och inte vilken tillställning
                        som helst. Det var Tårtmästerskapet i Bullerbyn 2023! Men Harald
                        noterar att stora delar av inredningen är sönderknådade och
                        ytterdörren står uppbruten. Det tyder på inbrott! Hur kan Harald ha
                        missat det när han kom hem igår?
                        <br />
                        <br />
                        Harald hasar sig genast till sitt kassaskåp i källaren och håller
                        varenda tass som går att hålla.
                        <br />
                        <br />
                        "Dom får inte ha tagit mitt hemliga recept!" yttrar han högt och
                        skakigt för sig själv. Men tyst, för han vill inte väcka Harriet.
                        <br />
                        <br />
                        Ve och fasa, kassaskåpet är uppbrutet! Hjälp Harald att hitta sina
                        viktiga dokument!
                    </p>
                    <h3 class="mt-50">2022 - Påskharen är borta!</h3>
                    <p className="text-left">
                        Harald Von Hopp, mer känd under sitt alias "Påskharen", är
                        försvunnen efter kontakt med misstänkt underrättelseofficer från
                        Nordpolen i syd-Polen. Ditt jobb är att lösa ett antal uppgifter för
                        att skapa en fördjupad förståelse av vem Harald var som person, om
                        detta mystiska försvinnande är självorkestrerat eller har skett mot
                        Haralds vilja samt vilket förhållandet är mellan Harald och
                        Nordpolen.
                        <br />
                        <br />
                        Efter genomsökning av Haralds bo påträffades följande material av
                        potentiellt intresse:
                        <ul className="normal-ul text-left">
                            <li>
                                Post-it-lappar med massa tecken som inte kunde tydas på plats.
                            </li>
                            <li>
                                En svart telefonbok innehållandes namn och telefonnummer till
                                olika personer, med ett antal namn kända av Hopps organisation
                                sedan tidigare.
                            </li>
                            <li>
                                Olika former av lagringsmedia; USB-stickor, externa hårddiskar
                                samt även cd-skivor och hålkort.
                            </li>
                            <li>Klistermärken föreställande en halv lök.</li>
                            <li>En bok om sudoku.</li>
                            <li>
                                En anslagstavla med väldigt många streck på; det verkar som om
                                Harald hade kört fast i ett problem.
                            </li>
                            <li>Kopior togs på plats av diverse filer och databaser.</li>
                        </ul>
                        I dagsläget misstänks Harald Von Hopp för att på frivillig väg ha
                        delat säkerhetsklassad information med främmande makt från
                        Nordpolen.
                        <br />
                        <br />
                        På baksidan av ett av lök-klistermärkena har någon, med spretig
                        handstil och en blyertspenna (hårdhet 2HB, tillhör Statskontoret),
                        plitat ner ett meddelande. Det står "Varning från T.N.", följt av en
                        lång radda tecken.
                    </p>
                </Header>
            </PageContent>
        </PageContainer>
    );
}
