import React from "react";
import styled from 'styled-components';


const Container = styled.div`
	width: 100%;
	height: 100%;
	margin: 0 auto;
`;

const Content = styled.div`
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 40vh;
    color: #dedede;
`;

export function NotFound() {
    return (
		<Container>
			<Content>
				<h1>404</h1>
				<h2>Not Found</h2>
            </Content>
		</Container>
	);
}