import React from "react";
import styled from "styled-components";
import { Link, Route, Routes } from "react-router-dom";

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

const PageContent = styled.div`
  width: 90%;
  max-width: 800px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 6em auto;
  margin-top: 1em;

  .mt-100 {
    margin-top: 100px;
  }
`;

const Header = styled.div`
  width: 90%;
  max-width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  date {
    font-weight: bold;
  }

  h1 {
    margin-top: 15px;
    margin-bottom: 25px;
  }

  h2 {
    margin-top: 25px;
    margin-bottom: 10px;
  }

  p {
    margin: 4px auto;
  }

  a {
    color: white;
  }

  .border {
    &::after,
    &::before,
    span::after,
    span::before {
      content: "";
      position: absolute;
      background-color: white;
      transition: 0.5s ease;
    }

    &::after,
    &::before {
      height: 100%;
      width: 2px;
      top: -2px;
    }

    &::after {
      left: -2px;
    }

    &::before {
      right: -2px;
    }

    span::after,
    span::before {
      height: 2px;
      width: 100%;
      left: -2px;
    }
    span::after {
      top: -2px;
    }

    span::before {
      bottom: -2px;
    }

    &:hover:after {
      transform: translatex(-12px);
    }

    &:hover:before {
      transform: translatex(12px);
    }

    &:hover span::after {
      transform: translatey(-12px);
    }

    &:hover span::before {
      transform: translatey(12px);
    }
  }

  .swap {
    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 1rem 2rem;
      box-sizing: border-box;
      opacity: 0;
      color: black;
      transition: 0.35s ease;
    }

    &::before {
      content: "";
      left: 0;
      top: 0;
      position: absolute;
      width: 0;
      height: 100%;
      background-color: white;
      transition: 0.5s ease;
    }

    &:hover {
      &:before {
        width: 100%;
      }

      span {
        opacity: 1;
      }
    }
  }

  .future {
    &::after,
    &::before {
      content: "";
      position: absolute;
      border: 2px solid white;
      width: calc(100% + 15px);
      height: 60px;
      transition: 0.5s ease;
    }

    &::after {
      top: -15px;
      left: -15px;
      border-right: none;
      border-bottom: none;
    }

    &::before {
      bottom: -15px;
      right: -15px;
      border-left: none;
      border-top: none;
    }

    &:hover:after,
    &:hover:before {
      width: calc(100% - 30px);
      height: 80px;
    }
  }
`;

const NiceButton = styled.a`
  padding: 0.3rem 1rem;
  font-size: 1rem;
  position: relative;
  border: 2px solid white;
  color: white;
  text-decoration: none;
  float: right;

  &.mt-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  &::after,
  &::before {
    content: "";
    position: absolute;
    border: 2px solid white;
    width: calc(100% + 15px);
    height: 30px;
    transition: 0.5s ease;
  }

  &::after {
    top: -10px;
    left: -10px;
    border-right: none;
    border-bottom: none;
  }

  &::before {
    bottom: -10px;
    right: -10px;
    border-left: none;
    border-top: none;
  }

  &:hover:after,
  &:hover:before {
    width: calc(100% - 20px);
    height: 45px;
  }
`;

export function Agencies() {
  return (
    <PageContainer>
      <PageContent>
        <Header>
          <h1>Underrättelsetjänsterna</h1>

          <h2 id="must">
            <img
              src="/static/img/crest_fm.svg"
              alt="Försvarsmakten"
              width="250"
            />
            <br />
            Militära underrättelse- och säkerhetstjänsten (Must)
          </h2>
          <p className="text-left">
            Must är Sveriges civila och militära underrättelsetjänst samt
            militära säkerhetstjänst. Musts uppdrag är dels att ge stöd för
            svensk utrikes-, försvars- och säkerhetspolitik genom att kartlägga
            och analysera yttre hot mot Sverige och dels att förebygga, upptäcka
            och motverka de säkerhetshot som riktas mot Försvarsmakten och dess
            intressen i Sverige och utomlands. Must är en del av myndigheten
            Försvarsmakten och inriktas av såväl regeringen som
            överbefälhavaren. Must verksamhet fyller en viktig roll i Sveriges
            försvars- och krisberedskap och ger Sverige förutsättningar att föra
            en självständig utrikes-, försvars- och säkerhetspolitik.
            Försvarsunderrättelseverksamheten inriktas av regeringen och är
            reglerad i lag, Försvarsunderrättelselagen. Den granskas fortlöpande
            av fristående kontrollorgan i syfte att kontrollera att Must löser
            sitt uppdrag inom lagens ramar. Must stödjer regeringen och
            överbefälhavaren med att:
            <ul className="normal-ul">
              <li class="text-left">
                Ta fram underrättelser till stöd för kunskapsuppbyggnad och
                beslutsfattande
              </li>
              <li class="text-left">Förvarna om förändringar i hotbilden</li>
              <li class="text-left">
                Bedriva säkerhetstjänst inom Försvarsmakten samt utöva tillsyn
                över andra myndigheter avseende säkerhetsskydd och signalskydd
              </li>
              <li class="text-left">Samordna försvarsattachéverksamheten</li>
              <li class="text-left">
                Organisera och leda insatsförbandet Nationella
                underrättelseenheten (NUE)
              </li>
            </ul>
            Genom detta bidrar Must till ett starkare försvar. Underlagen som
            Must lämnar till överbefälhavaren och övriga Försvarsmakten ligger
            till grund för såväl målsättningsarbete och kravställningar som
            anpassning av beredskap och genomförande av insatser. Därmed stödjer
            Must såväl Försvarsmaktens långsiktiga förmågeutveckling,
            försvarsplanering, och operativa planering.
            <br />
            <br />
            <NiceButton
              className="mono"
              href="https://jobb.forsvarsmakten.se/sv/ledigajobb/#/staffcategory/militara-underrattelse--och-sakerhetstjansten"
              target="_blank"
            >
              Lediga jobb
            </NiceButton>
          </p>

          <h2 class="mt-100" id="fra">
            <img
              src="/static/img/crest_fra.svg"
              alt="Försvarets Radioanstalt"
            />
            <br />
            Försvarets radioanstalt (FRA)
          </h2>
          <p className="text-left">
            FRA bidrar till att skydda Sverige och svenska intressen. Det sker
            genom rapportering om viktiga utländska förhållanden av betydelse
            för svensk utrikes-, säkerhets- och försvarspolitik. Det sker också
            genom arbete med att skapa förutsättningar för ett cyberförsvar och
            att bidra till att skydda samhällsviktiga informationssystem.
            <br />
            <br />
            FRA har två verksamhetsområden - signalunderrättelseverksamhet och
            cyberförsvar.
            <br />
            <br />
            FRA förser regeringen och övriga uppdragsgivare med information om
            utländska förhållanden. Det kan till exempel handla om militär
            förmåga i andra länder, utvecklingen i krigs- och konfliktregioner
            eller hur IT-angrepp sker i det globala nätet. Våra spanings- och
            bearbetningsmetoder utvecklas kontinuerligt för att möta de
            omvärldsförändringar som sker. Det betyder att vi hela tiden måste
            försöka ligga steget före så att vi inte bara ser till dagens
            underrättelsebehov, utan också kan möta framtidens utmaningar.
            <br />
            <br />
            Ur ett nationellt perspektiv behövs ett cyberförsvar som skyddar den
            digitala territorialgränsen. Utan detta riskerar ett land att
            utarmas ekonomiskt, politiskt och säkerhetsmässigt. För att värna de
            mest skyddsvärda verksamheterna arbetar FRA, Försvarsmakten och
            Säkerhetspolisen nära tillsammans. För att utveckla ett cyberförsvar
            mot de mest kvalificerade angreppen behövs framförallt tre
            komponenter som samverkar och förstärker varandra: signalspaning,
            skyddsåtgärder och motåtgärder.
            <br />
            <br />
            <NiceButton
              className="mono"
              href="https://fra.se/jobbahososs/ledigajobb.4.55af049f184e92956c42a5f.html"
              target="_blank"
            >
              Lediga jobb
            </NiceButton>
          </p>

          <h2 class="mt-100" id="sapo">
            <img src="/static/img/crest_sapo.svg" alt="Säkerhetspolisen" />
            <br />
            Säkerhetspolisen (Säpo)
          </h2>
          <p className="text-left">
            Säkerhetspolisen arbetar med att förebygga och avslöja brott mot
            Sveriges säkerhet, bekämpa terrorism och skydda den centrala
            statsledningen. Det gör vi för att skydda det demokratiska systemet,
            medborgarnas fri- och rättigheter samt den nationella säkerheten.
            Det är Säkerhetspolisens ansvar att det som inte får hända inte
            heller händer.
            <br />
            <br />
            Säkerhetspolisen är en säkerhetstjänst med ett nationellt uppdrag
            som polismyndighet. En säkerhetstjänst arbetar för att höja
            säkerhetsnivån i det egna landet. Detta görs dels genom att avslöja
            och reducera säkerhetshot som riktas mot landet och dess skyddsvärda
            verksamheter, dels genom att reducera sårbarheter i dessa
            verksamheter.
            <br />
            <br />
            Säkerhetspolisen har idag fem verksamhetsområden, författningsskydd,
            personskydd, kontraspionage, kontraterrorism samt säkerhetsskydd.
            <br />
            <br />
            <NiceButton
              className="mono"
              href="https://sakerhetspolisen.se/jobba-hos-oss/lediga-jobb.html"
              target="_blank"
            >
              Lediga jobb
            </NiceButton>
          </p>

          <h2 class="mt-100">Samarbete mellan myndigheterna</h2>
          <p className="text-left">
            Must, Säkerhetspolisen och Försvarets Radioanstalt (FRA) har ett
            utvecklat samarbete, ett strategiskt och operativt arbete finns på
            alla nivåer. Cheferna för Must, Säkerhetspolisen och FRA träffas
            regelbundet för att diskutera aktuella frågor. Medarbetare inom
            olika ansvarsområden har gemensamma avstämningar och en samverkan
            sker mellan myndigheterna i gemensamma ärenden. Must, FRA och Säpo
            samarbetar i olika frågor där det finns gemensamma intressen. Det
            kan handla om att dela information och kunskap, att samverka i
            gemensamma utredningar eller att samarbeta i olika projekt.
          </p>
        </Header>
      </PageContent>
    </PageContainer>
  );
}
