import React, { useEffect, useState } from "react";

const Clock = ({
  deadline,
  labels = {
    days: "Days",
    hours: "Hours",
    minutes: "Minutes",
    seconds: "Seconds"
  }
}) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const leading0 = num => {
    return num < 10 ? "0" + num : num;
  };

  const getTimeUntil = deadline => {
    const time = Date.parse(deadline) - Date.parse(new Date());
    if (time < 0) {
      setDays(0);
      setHours(0);
      setMinutes(0);
      setSeconds(0);
    } else {
      setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
      setHours(Math.floor(time / (1000 * 60 * 60) % 24));
      setMinutes(Math.floor(time / 1000 / 60 % 60));
      setSeconds(Math.floor(time / 1000 % 60));
    }
  };

  useEffect(
    () => {
      setInterval(() => getTimeUntil(deadline), 1000);

      return () => getTimeUntil(deadline);
    },
    [deadline]
  );

  return (
    <div className="Clock">
      <div className="Clock-unit Clock-days">
        <span className="Clock-value">
          {leading0(days)}
        </span>
        <span className="Clock-label">
          {labels.days}
        </span>
      </div>
      <div className="Clock-unit Clock-hours">
        <span className="Clock-value">
          {leading0(hours)}
        </span>
        <span className="Clock-label">
          {labels.hours}
        </span>
      </div>
      <div className="Clock-unit Clock-minutes">
        <span className="Clock-value">
          {leading0(minutes)}
        </span>
        <span className="Clock-label">
          {labels.minutes}
        </span>
      </div>
      <div className="Clock-unit Clock-seconds">
        <span className="Clock-value">
          {leading0(seconds)}
        </span>
        <span className="Clock-label">
          {labels.seconds}
        </span>
      </div>
    </div>
  );
};

export default Clock;
